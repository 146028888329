import { FC, useState } from "react"

import { navigateToSearchResults, setFilters } from "src/components/state/filters"

import { SearchInput } from "./SearchInput"

export const SimpleSearchForm: FC = () => {
  const [value, setValue] = useState("")

  return (
    <section role="search" className="max-w-2xl">
      <SearchInput
        value={value}
        onChange={(value) => {
          setValue(value)
          setFilters({ q: value })
        }}
        onSubmit={() => {
          navigateToSearchResults()
        }}
      />
    </section>
  )
}
